footer {
  background: #292f35;
  padding: 4rem 0;
}

.copyright {
  color: #fff;
  font: 700 1.2rem "Poppins", sans-serif;
  margin-left: 10rem;
}

@media (max-width: 768px) {
  footer {
    width: 100%;
  }
  .copyright {
    margin-left: 2rem;
    font: 700 1rem "Poppins", sans-serif;
  }
}
