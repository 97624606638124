.pricing-table {
  padding: 0px 0px 40px 0px;
  margin: 0 auto;
  margin-bottom: 5px;
  margin-top: 4rem;
  border: transparent;
  text-align: center;
  background-color: #ffffff;
  border: 1px solid #f1f1f1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  width: 80%;
  font-family: "Poppins", sans-serif;
}

.pricing-table:hover {
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 10%);
}

.pricing-table .pricing-details h2 {
  font-size: 26px;
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
  text-transform: uppercase;
  background: #f1f1f1;
  padding: 30px 0;
}

.pricing-table .pricing-details h2::before {
  border: none;
}

.pricing-table .pricing-details span {
  display: inline-block;
  font-size: 4.8rem;
  font-weight: 400;
  color: var(--second-color);
  margin-bottom: 2rem;
}

.pricing-table .pricing-details ul {
  margin-bottom: 3.5rem;
}

.pricing-table .pricing-details ul li {
  padding: 10px;
  font-size: 15px;
  border-bottom: 1px dotted #f1f1f1;
}

.pricing-table .pricing-details img {
  width: 30rem;
}