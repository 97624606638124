* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

ul,
li {
  list-style: none;
}

img{
  max-width: 100%;
}

:root {
  font-size: 62.5%;
  --primary-color: #ffb962;
  --second-color: #1379e2;
  --black-color: #333;
}
