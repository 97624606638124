@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 8rem;
  height: 10rem;
  width: 100%;
  transition: 0.5s;
  position: absolute;
  z-index: 999;
  animation: fadeIn 2s ease;
}

header.fixed {
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 1px 1px 8px #333;
}

header img {
  max-width: 16rem;
}

header.fixed .menu .menu-item a {
  color: var(--black-color);
}

header.fixed .menu .menu-item a:hover {
  color: var(--primary-color);
}

.cel_navigator {
  background-color: var(--primary-color);
  position: fixed;
  top: 0;
  right: -40rem;
  height: 110vh;
  width: 60%;
  z-index: 999;
  display: none;
  opacity: 0;
  flex-direction: column;
  transition: 0.7s;
}

.cel_navigator.active {
  opacity: 1;
  right: 0;
}

header button {
  color: var(--primary-color);
  background: none;
  border: none;
  font-size: 3rem;
  display: none;
}

.cel_navigator.active .menu-item button {
  color: #f1faf5;
}

.cel_navigator .menu-item {
  font: 500 1.6rem "Poppins", sans-serif;
  padding: 2rem 1rem 1rem;
  border-bottom: 2px solid #f1c289ad;
  color: #f1faf5;
}

.cel_navigator .menu-item:last-child a {
  color: #1379e2;
}

.menu {
  display: inline;
}

.menu .menu-item {
  display: inline;
  margin-left: 1.6rem;
}

.menu .menu-item a {
  font: 500 1.5rem "Poppins", sans-serif;
  color: #fff;
  cursor: pointer;
  transition: 0.3s;
  padding: 0.8rem 1.5rem;
  position: relative;
}

.menu .menu-item a:hover {
  color: var(--primary-color);
}

.menu .menu-item a.active {
  background-color: var(--primary-color);
  padding: 1rem 1.8rem;
  border-radius: 30px;
  color: var(--black-color);
}

header.fixed .menu .menu-item a.active:hover {
  color: #fff;
}

.menu .menu-item:last-child a {
  border: 2px solid var(--primary-color);
  border-radius: 30px;
}

.menu .menu-item:last-child a::before {
  content: "";
  position: absolute;
  top: -0.1px;
  left: -2px;
  background: var(--primary-color);
  width: 102%;
  height: 103%;
  border-radius: 30px;
  z-index: -2;
  opacity: 0;
  transition: 0.3s;
}

header.fixed .menu .menu-item:last-child a:hover::before,
header.fixed .menu .menu-item:last-child a:hover,
header .menu .menu-item:last-child a:hover::before,
header .menu .menu-item:last-child a:hover {
  opacity: 1;
  color: #1b1b1a;
}

@media (max-width: 768px) {
  header {
    padding: 0;
    padding: 0 2rem;
  }
  header nav .menu {
    display: none;
  }
  header img {
    max-width: 14rem;
  }
  header button {
    display: block;
  }
  .cel_navigator {
    display: flex;
  }
}
