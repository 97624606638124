@keyframes banner {
  from {
    opacity: 0;
    transform: translate3d(0, -2rem, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.top {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;
  position: relative;
  overflow: hidden;
}

.banner {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-family: "Changa One", sans-serif;
  text-shadow: 0px 2px 3px rgb(56, 56, 56);
}

.top .video_banner {
  width: 100%;
  height: auto;
  z-index: -1;
  opacity: 0.2;
}

.banner::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -2;
  background-image: url("../../assets/img/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  filter: brightness(70%);
}

.top .banner h1 {
  text-align: center;
  font-size: 5.8rem;
  letter-spacing: 0.2rem;
  animation: banner 1s 0.8s ease forwards;
  opacity: 0;
}

.top .banner svg {
  opacity: 0;
  font-size: 8rem;
  margin-bottom: 3rem;
  color: var(--primary-color);
  animation: banner 1s 0.5s ease forwards;
}

.top .banner h2 {
  color: var(--primary-color);
  font-size: 3rem;
  margin-top: 1rem;
  font-family: "Taviraj", sans-serif;
  animation: banner 1s 1.2s ease forwards;
  opacity: 0;
}

.top .banner p {
  margin-top: 8rem;
  font-size: 3.2rem;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  background: rgb(250, 185, 98);
  background: linear-gradient(
    36deg,
    rgba(250, 185, 98, 1) 0%,
    rgba(250, 200, 134, 1) 100%
  );
  color: var(--black-color);
  padding: 1rem 2rem;
  border-radius: 20px;
  text-shadow: none;
  animation: banner 1s 1.5s ease forwards;
  opacity: 0;
}

.top .banner p svg {
  margin: 0;
  margin-left: 0.5rem;
  font-size: 3.2rem;
  color: var(--black-color);
}

@media (max-width: 768px) {
  .top .banner h1 {
    font-size: 4rem;
    width: 90%;
  }
  .top .banner h2 {
    font-size: 2.2rem;
    width: 90%;
    text-align: center;
  }
  .top .banner p {
    font-size: 2.3rem;
    margin-top: 4rem;
  }
  .top .banner p svg {
    font-size: 2.4rem;
  }
  .top .video_banner {
    width: auto;
    height: 100%;
  }
}
