.scrollTop {
  position: fixed;
  bottom: 3rem;
  right: 5rem;
  align-items: center;
  font-size: 4rem;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.7;
  color: var(--primary-color);
}

.warning {
  color: rgb(211, 55, 55);
  font-size: 2.4rem;
  max-width: 90%;
  margin: 0 auto;
  display: block;
}

.scrollTop:hover {
  opacity: 1;
}

.caravana {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  color: var(--black-color);
}

.caravana h2 {
  font-size: 4rem;
  font-family: "Poppins", sans-serif;
  line-height: 1.5;
  text-shadow: 0px 1px 2px rgb(204, 204, 204);
  margin-top: 5rem;
  position: relative;
}

.caravana h2::before {
  border-bottom: 3px solid var(--primary-color);
  content: "";
  position: absolute;
  width: 10rem;
  bottom: 0.5rem;
  left: 6.5rem;
}

.caravana article {
  margin-top: 1rem;
  font-size: 1.8rem;
  font-family: "Poppins", sans-serif;
  width: 70%;
}

.caravana article p {
  margin-top: 1.8rem;
}

.caravana img {
  margin-top: 5rem;
}

.caravana figcaption {
  color: hsl(211deg 85% 50%);
  font: 700 4rem/1.5 "Taviraj", sans-serif;
  margin-top: 3rem;
}

.caravana figure {
  margin-bottom: 5rem;
}

.lideranca {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 5rem;
  text-align: center;
  background: url("../../assets/img/bg2.jpg") fixed no-repeat;
  background-size: cover;
  color: #fff;
}

.lideranca h2 {
  font-size: 4.2rem;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  text-shadow: 0px 1px 2px rgb(61, 61, 61);
  position: relative;
  line-height: 1.5;
  margin-top: 7rem;
}

.lideranca h2::before {
  border-bottom: 3px solid var(--primary-color);
  content: "";
  position: absolute;
  width: 14rem;
  bottom: 0.5rem;
  left: 7.5rem;
}

.lideranca article {
  margin-top: 1rem;
  font-size: 1.5rem;
  line-height: 2;
  font-family: "Poppins", sans-serif;
  width: 95rem;
}

.lideranca article p {
  margin-top: 1.8rem;
}

.lideranca img {
  margin-top: 5rem;
}

.lideranca figcaption {
  color: #fff;
  font: 700 4rem/1.5 "Taviraj", sans-serif;
  margin-top: 3rem;
}

.lideranca figure {
  margin-bottom: 7rem;
}

.photos {
  width: 100%;
  padding: 7rem 5rem;
  text-align: center;
  color: var(--black-color);
}

.photos h2 {
  font-size: 4.2rem;
  line-height: 1.5;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  position: relative;
}

.photos h2::before {
  border-bottom: 3px solid var(--primary-color);
  content: "";
  position: absolute;
  width: 8rem;
  bottom: 0.5rem;
  left: 61rem;
}

.photos p {
  margin: 2rem auto;
  font-size: 1.8rem;
  line-height: 2;
  font-family: "Poppins", sans-serif;
  width: 82rem;
}

.photos .photo_item {
  width: 90% !important;
}

.photos .photo_item img {
  width: 100%;
  height: 100%;
}

.slick-dots li button:before {
  font-size: 1.2rem;
  margin-top: 2rem !important;
}

.video {
  background: rgb(54, 122, 226);
  background: linear-gradient(
    45deg,
    rgba(54, 122, 226, 1) 0%,
    rgba(10, 73, 170, 1) 100%
  );
  color: #fff;
  font-family: "Poppins", sans-serif;
  width: 100%;
  padding: 5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.video h2 {
  max-width: 80rem;
  font-size: 2.8rem;
  text-align: center;
  margin-top: 1rem;
  font-weight: 400;
}

.video iframe {
  margin-top: 4rem;
}

.investiment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5rem;
  text-align: center;
  color: var(--black-color);
}

.investiment h2 {
  font-size: 4.2rem;
  line-height: 1.5;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  position: relative;
}

.investiment h2::before {
  border-bottom: 3px solid var(--primary-color);
  content: "";
  position: absolute;
  width: 8rem;
  bottom: 0.5rem;
  left: 11.5rem;
}

.investiment p {
  margin-top: 2rem;
  font-size: 1.8rem;
  line-height: 2;
  font-family: "Poppins", sans-serif;
  width: 82rem;
}

.roteiro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 5rem;
  text-align: center;
  color: var(--black-color);
}

.roteiro h2 {
  font-size: 4.2rem;
  line-height: 1.5;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  position: relative;
}

.roteiro h2::before {
  border-bottom: 3px solid var(--primary-color);
  content: "";
  position: absolute;
  width: 8rem;
  bottom: 0.5rem;
  left: 5rem;
}

.roteiro p {
  margin-top: 2rem;
  font-size: 1.8rem;
  line-height: 2;
  font-family: "Poppins", sans-serif;
  width: 82rem;
}

.register {
  background: rgb(54, 122, 226);
  background: linear-gradient(
    45deg,
    rgba(54, 122, 226, 1) 0%,
    rgba(10, 73, 170, 1) 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 36rem;
  color: #fbfbfb;
  font-family: "Poppins", sans-serif;
  margin-top: 5rem;
}

.register h2 {
  font-size: 2.6rem;
  line-height: 1.5;
  max-width: 80%;
  text-align: center;
}

.register a {
  color: #1b1b1a;
  margin-top: 3.6rem;
  background: rgb(250, 185, 98);
  background: linear-gradient(
    36deg,
    rgba(250, 185, 98, 1) 50%,
    rgba(250, 200, 134, 1) 100%
  );
  padding: 1.6rem 4rem;
  display: flex;
  font-weight: 700;
  font-size: 2rem;
  align-items: center;
  border-radius: 15px;
  text-transform: uppercase;
  transition: 0.3s;
}

.contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 7rem 5rem;
  text-align: center;
  color: var(--black-color);
}

.contact h2 {
  font-size: 4.2rem;
  line-height: 1.5;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  position: relative;
}

.contact h2::before {
  border-bottom: 3px solid var(--primary-color);
  content: "";
  position: absolute;
  width: 8rem;
  bottom: 0.5rem;
  left: 7rem;
}

.contact img {
  margin-top: 5rem;
}

.contact p {
  font-size: 1.6rem;
  line-height: 2;
  font-family: "Poppins", sans-serif;
  width: 82rem;
  font-weight: 700;
}

.contact a {
  color: #fff;
  text-decoration: none;
  background: var(--second-color);
  font: 300 1.6rem "Poppins", sans-serif;
  text-transform: uppercase;
  border-radius: 30px;
  padding: 1.5rem 3rem;
  margin-top: 3rem;
}

@media (max-width: 768px) {
  .caravana {
    padding: 7rem 3rem;
  }
  .caravana article {
    width: 100%;
  }
  .caravana article p {
    text-align: justify;
  }
  .caravana figcaption {
    font-size: 3rem;
  }
  .lideranca {
    padding: 7rem 3rem;
  }
  .lideranca article {
    width: 100%;
  }
  .lideranca article p {
    width: 100%;
    text-align: justify;
  }
  .lideranca img {
    width: 100%;
  }
  .lideranca figcaption {
    font-size: 3rem;
    margin-top: initial;
  }
  .lideranca h2::before {
    left: 6rem;
  }
  .roteiro p {
    width: 100%;
    line-height: 1.5;
  }
  .roteiro h2::before {
    left: 4rem;
  }
  .photos {
    padding: 7rem 3rem;
  }
  .photos h2 {
    font-size: 3.4rem;
  }
  .photos h2::before {
    left: 11rem;
  }
  .photos p {
    width: 100%;
    line-height: 1.5;
  }
  .photos .photos_grid {
    padding: 0;
    grid-template-columns: none;
  }
  .video {
    padding: 5rem 2rem;
  }
  .video h2 {
    width: 100%;
    font-size: 2rem;
  }
  .video iframe {
    max-width: 100%;
  }
  .investiment {
    padding: 5rem 3rem;
  }
  .investiment p {
    width: 100%;
    line-height: 1.5;
  }
  .investiment h2,
  .roteiro h2,
  .contact h2,
  .photos h2,
  .lideranca h2 {
    font-size: 3.6rem;
  }
  .investiment h2::before {
    left: 9rem;
  }
  .pricing-table {
    margin: 0;
    margin-top: 2rem;
    width: 100%;
  }
  .pricing-table .pricing-details span {
    font-size: 2.8rem;
  }
  .pricing-table .pricing-details span span {
    padding: 0 2rem;
  }
  .roteiro {
    padding: 5rem 3rem;
  }
  .register h2 {
    font-size: 2rem;
  }
  .register a {
    font-size: 1.6rem;
  }
  .contact {
    padding: 5rem 3rem;
  }
  .contact img,
  .contact p {
    width: 100%;
    font-size: 1.4rem;
  }
  .contact h2::before {
    left: 5rem;
  }
  .warning {
    font-size: 1.8rem;
  }
}
